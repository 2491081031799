<script setup>
import { ClipboardDocumentListIcon, ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline'
import Section1CTA from '@components/home/Section1CTA.vue'
import Subtitle from '@components/Subtitle.vue'

const features = [
  {
    title: 'What is an ABN?',
    description:
      'The ABN (Australian Business Number) is an eleven digit business identification number, used by the government to identify your unique business. It allows you to run your own business, offering your services to as many companies or individuals as you want.',
    subtitle: 'To register your ABN with us you will need:',
    list: [
      'your Identification Documents',
      'your tax file number (TFN)',
      'a basic explanation of the services you plan to provide',
    ],
    subtitle2: 'To re-apply for (reactivate) your ABN',
    description2:
      'If your ABN has been cancelled, you will need to re-apply for an ABN. Please click on the button below in order to reactivate your ABN.',
    button: {
      href: '/abn-register',
      text: 'Renew my ABN',
    },
  },
  {
    title: 'Is an ABN right for me?',
    subtitle: 'Registering your own ABN allows you to:',
    list: [
      'avoid pay as you go (PAYG) tax',
      'maximise their business expenses tax deduction potential',
      'get an Australian domain name (.com.au)',
      'claim energy grants credits',
      'claim goods and services tax (GST) credits',
      'correctly identify your business to others when ordering and invoicing',
    ],
    subtitle2: 'ABN Finder - Find your active ABN at no cost',
    description2:
      'Have you lost your ABN? Not sure if your ABN is still active? Please check the button below to find your ABN.',
    button: {
      href: '/find',
      text: 'Find my ABN',
    },
  },
]
</script>

<template>
  <div class="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8" dusk="ABNRegistrationOnline">
    <div class="mx-auto max-w-2xl lg:text-center">
      <Subtitle>
        Are you looking to start your own business?
      </Subtitle>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        You've come to the right place. Registering for an Australian Business
        Number (ABN) is simple and fast.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <dl class="grid gap-x-8 gap-y-12 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
        <div v-for="feature in features" :key="feature.name" class="flex flex-col gap-4">
          <dt class="flex items-center gap-x-3 text-2xl font-semibold leading-7 text-gray-900">
            {{ feature.title }}
          </dt>
          <dd v-if="feature.description" class="text-base leading-7 text-gray-600">
            {{ feature.description }}
          </dd>
          <dd class="text-lg font-semibold leading-7 text-gray-800">
            {{ feature.subtitle }}
          </dd>
          <ul class="list-decimal list-inside text-gray-700">
            <li v-for="(item, index) in feature.list" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>

        <div v-for="feature in features" :key="feature.name" class="flex flex-col gap-4">
          <Section1CTA
            :subtitle="feature.subtitle2"
            :description="feature.description2"
            :href="feature.button.href"
            :text="feature.button.text"
        />
        </div>

      </dl>
    </div>
  </div>
</template>
